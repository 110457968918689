#home {
    position: relative;
    
    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width:100%;
    height:110vh;
    column-gap: 12px;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width:2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width:1200px) {
        flex-direction: column;
    }   

    @media screen and (max-width:400px) {
        padding: 6rem 1rem 2rem;
    }  
}

.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 2000px) {

        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    .tag-custom {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }

    .tag-contact {
        cursor: pointer;
        transition: all ease-in 0.3s;
        

        &:hover {
            background-color: var(--secondary-color);
            color: white;
        }


        h1:hover {
            color: white;
        }
    }

    .badge-cmp, .tag-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0 0 20px rgba(0,0,0,0.1);
    }

    .tag-cmp {
        flex-direction: column;
        margin-top: 3rem;
        
        p {
            width: 100%;
            text-transform: uppercase;
            text-align: right;    
        }
    }

    span {
        font-size: 2.5rem;
        // For full hd laptop
        @media screen and (min-width: 2000px) {
            font-size: 5rem;
        }

    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-end;
        align-items: flex-start;
    }

}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
        // Why
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
        img {
            width: 60%;
            height: 60%;
        }
    }

    // Targeting the first child
    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }

    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }

    // If you are not good at this make them
    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }
    
    @media screen and (min-width:2000px) {
          // Targeting the first child
    div:nth-child(1) {
        width: 400px;
        height: 400px;
    }

    div:nth-child(2) {
        width: 200px;
        height: 200px;
    }

    // If you are not good at this make them
    div:nth-child(3) {
        width: 300px;
        height: 300px;
    }
    }
    @media screen and (max-width:1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;
        
        div {
            margin: 1rem;
        }
    }

}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;

    }

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index:0; 
        width: 100%;
        height: 90%;

    }
    
    @media screen and(max-width: 1200px) {
        margin: 2rem  0;            
    }
}

// Hand Animation
.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  
    animation-delay: 3s;
    transform-origin: 70% 70%;       
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) } 
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }
    100% { transform: rotate( 0.0deg) }
  }
  